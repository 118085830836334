var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "mb-2",
      attrs: { toggleable: "md", type: "dark", variant: "primary" },
    },
    [
      !_vm.plain
        ? _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } })
        : _vm._e(),
      _c("b-navbar-brand", { attrs: { to: "/loggers" } }, [
        _vm._v(" PMGateway "),
        _vm.staging ? _c("span", [_vm._v("(STAGING)")]) : _vm._e(),
      ]),
      !_vm.plain
        ? _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                [
                  _c(
                    "b-nav-item",
                    [
                      _c(
                        "b-breadcrumb",
                        { staticClass: "bg-primary mh-100" },
                        _vm._l(_vm.$route.meta.breadcrumbs, function (bc) {
                          return _c(
                            "b-breadcrumb-item",
                            { key: bc.text },
                            [
                              _c(
                                "b-link",
                                {
                                  staticClass: "text-light",
                                  attrs: { to: bc.to },
                                },
                                [_vm._v(_vm._s(bc.text))]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.plain
                ? _c(
                    "b-navbar-nav",
                    { staticClass: "ml-auto" },
                    [
                      _vm.oem
                        ? _c(
                            "b-nav-item",
                            {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.bottom",
                                  value: "OEM Panel",
                                  expression: "'OEM Panel'",
                                  modifiers: { hover: true, bottom: true },
                                },
                              ],
                              attrs: { to: "/oem", right: "" },
                            },
                            [
                              _c("fa-icon", { attrs: { icon: "wrench" } }),
                              _c("span", { staticClass: "d-md-none ml-4" }, [
                                _vm._v("OEM Panel"),
                              ]),
                              _vm.oemCount && _vm.oemCount > 0
                                ? _c(
                                    "b-badge",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { variant: "light" },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.oemCount) + " ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-nav-item",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.bottom",
                              value: "Notifications",
                              expression: "'Notifications'",
                              modifiers: { hover: true, bottom: true },
                            },
                          ],
                          attrs: { right: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.show("notification-modal")
                            },
                          },
                        },
                        [
                          _c("fa-icon", { attrs: { icon: "bell" } }),
                          _c("span", { staticClass: "d-md-none ml-4" }, [
                            _vm._v("Notifications"),
                          ]),
                          _vm.notificationCount > 0
                            ? _c(
                                "b-badge",
                                {
                                  staticClass: "ml-1",
                                  attrs: { variant: "light" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.notificationCount) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-nav-item",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.bottom",
                              value: "Leave Feedback",
                              expression: "'Leave Feedback'",
                              modifiers: { hover: true, bottom: true },
                            },
                          ],
                          attrs: { to: "/feedback", right: "" },
                        },
                        [
                          _c("fa-icon", { attrs: { icon: "comment-dots" } }),
                          _c("span", { staticClass: "d-md-none ml-4" }, [
                            _vm._v("Feedback"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-nav-item",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.bottom",
                              value: "Help",
                              expression: "'Help'",
                              modifiers: { hover: true, bottom: true },
                            },
                          ],
                          attrs: { to: "/help", right: "" },
                        },
                        [
                          _c("fa-icon", { attrs: { icon: "question-circle" } }),
                          _c("span", { staticClass: "d-md-none ml-4" }, [
                            _vm._v("Help"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.plain
                ? _c(
                    "b-navbar-nav",
                    [
                      _c(
                        "b-nav-item-dropdown",
                        { attrs: { right: "" } },
                        [
                          _c("template", { slot: "button-content" }, [
                            _c("em", [
                              _vm._v(
                                " " + _vm._s(this.$store.getters["user/name"])
                              ),
                            ]),
                          ]),
                          _c("b-dropdown-item", { attrs: { disabled: "" } }, [
                            _vm._v(
                              " " +
                                _vm._s(this.$store.getters["user/email"]) +
                                " "
                            ),
                          ]),
                          _c("b-dropdown-item", { attrs: { disabled: "" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.$store.getters["user/organisation"]
                                ) +
                                " "
                            ),
                          ]),
                          _c("b-dropdown-divider"),
                          _c("b-dropdown-item", { attrs: { to: "/profile" } }, [
                            _vm._v("Profile"),
                          ]),
                          _c("b-dropdown-item", { on: { click: _vm.logout } }, [
                            _vm._v("Sign Out"),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }